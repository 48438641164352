@use 'variables';
@use '../../node_modules/@eon-one/one-ui/styles/index' as one-ui;
@use './custom-material/custom-material';
@use './custom-one-ui/custom-one-ui';
@use './utils';
@use '@angular/material' as mat;
@use './custom-material/m3-theme.scss' as custom-material-theme;
@use './typography';
@use './components/components';

:root {
  @include one-ui.base {
    @include one-ui.eon-one-theme;
  }
}

$font-family: 'EONBrixSans', arial, geneva, helvetica, sans-serif;

* {
  font-family: $font-family;
}

.ng2-pdf-viewer-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: 0;

  overflow: hidden;
}

@include mat.elevation-classes;
@include mat.app-background;

html {
  @include one-ui.html;
  @include mat.elevation-classes;
  @include mat.app-background;
  @include mat.all-component-themes(custom-material-theme.$light-theme);
}
